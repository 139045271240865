import React, { useEffect, useState } from 'react';
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
import Pass from "../../component/img/pass.png";
import TermIcon from "../../component/img/info.png";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";

export const SubTicketItem = (props) => {

  const { itemList, currentStep } = props
  const { cartUpdate } = props

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  const [picturePath, setPicturePath] = useState(Pass)
  const [subTicketQty, setSubTicketQty] = useState([])
  const [subTotalT, setSubTotalT] = useState(0)
  const [selectedT, setSelectedT] = useState([])    // Ticket อยู่ใน Cart
  const [editT, setEditT] = useState([])            // Ticket ถูกแก้ไข
  const [showAddT, setShowAddT] = useState(true)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  useEffect(() => {

    if (itemList.PackageItemLists.length > 0) {
      let iList = itemList.PackageItemLists
      let tmpSubTicket = []
      let tmpSelectT = []
      let tmpEditT = []
      let subT = 0

      if (myCart !== null && myCart.length > 0) {
        // ค้นหาว่ามี SubTicket อยู่ใน Card หรือไม่
        let tIndex = -1
        let totalSelected = 0
        iList.forEach((c, index) => {
          tIndex = myCart.findIndex(e => {
            return (e.PackageCode === c.PackageCode && e.ItemCode === c.ItemCode)
          })

          // ถ้ามีรายการ SubTicket ใน Card
          if (tIndex !== -1) {
            tmpSubTicket.push({ ItemCode: c.ItemCode, Qty: myCart[tIndex].Qty })

            if (myCart[tIndex].status === "edit") {
              tmpSelectT.push("")
              tmpEditT.push(true)
              setShowAddT(true)
            } else {
              tmpSelectT.push("hidden")
              tmpEditT.push(false)
            }
            subT = subT + ((c.IsDiscount ? c.ItemPrice - c.DiscountPrice : c.ItemPrice) * myCart[tIndex].Qty)
            totalSelected++
          } else {
            tmpSubTicket.push({ ItemCode: c.ItemCode, Qty: 1 })
            tmpSelectT.push("")
            tmpEditT.push(false)
            subT = subT + (c.IsDiscount ? c.ItemPrice - c.DiscountPrice : c.ItemPrice)
          }
        })
        setSubTotalT(subT)
        if (totalSelected === iList.length && tmpEditT.filter(e => e === true).length === 0) {
          setShowAddT(false)
        }
      } else {
        iList.forEach(e => {
          tmpSubTicket.push({ ItemCode: e.ItemCode, Qty: 1 })
          tmpSelectT.push("")
          tmpEditT.push(false)
          subT = subT + (e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice)
        });
      }
      setSubTicketQty(tmpSubTicket)
      setSubTotalT(subT)
      setSelectedT(tmpSelectT)
      setEditT(tmpEditT)
    }

    if (itemList.PackagePictureLists > 0) {
      let picture = itemList.PackagePictureLists.filter(e => e.PictureSize.toLowerCase() === "small")[0]
      if (picture != null)
        setPicturePath(picture.PictureUrl)
      else
        setPicturePath(Pass)
    }

  }, [itemList])



  const addToCart = (item, isEdit) => {
    if (item.Qty > 0) {
      props.onAddToCart(item, isEdit)
    } else {
      props.onRemoveCart(item.PackageCode, item.ItemCode)
    }
  }

  useEffect(() => {
    if (cartUpdate.PackageCode === itemList.PackageCode) {
      let sIndex = subTicketQty.findIndex(e => {
        return (e.ItemCode === cartUpdate.ItemCode)
      })
      let sQty = [...subTicketQty]
      sQty[sIndex].Qty = cartUpdate.Qty
      setSubTicketQty(sQty)

      let sTotal = 0
      let netP = 0
      itemList.PackageItemLists.forEach((e, index) => {
        netP = e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice
        sTotal += netP * sQty[index].Qty

      });
      setSubTotalT(sTotal)
    }
  }, [cartUpdate])

  // ภาษา
  const { t } = useTranslation();

  return (
    <div className="box box-main">
      <div className="box-plus-header">
        <div className="row align-items-center h-100">
          <div className="col-lg-12 ">
            <h2 className="plus-title mb-0">{itemList.PackageName}</h2>
          </div>
        </div>
      </div>

      <div className="box-main-body bg-plus-1">
        <div className="row ">
          <div className="col-lg-6">
            <div className="wrapper-pass pt-4 text-center text-lg-start mb-3">
              <img src={picturePath} alt="" style={{ width: "100%" }} />
            </div>
            <div className="desc pt-4">
              <p className="font- text-desc weight-600 mb-4"><Markup content={itemList.PackageDescription} /></p>
              <p className="font-10 color-grey mb-3"><Markup content={itemList.PackageTermAndCondition} /></p>
            </div>
          </div>
          <div className="col-lg-6 ">
            {subTicketQty.length > 0 &&
              itemList.PackageItemLists.map((e, index) => {
                return (
                  <div className="box box-border mb-2 bg-white">
                    <div className="box-body  py-3">
                      <div className="row">
                        <div className="col-6">
                          <p className="font-14 weight-700 mb-0">{e.ItemName}</p>
                        </div>
                        {e.IsDiscount && (
                          <div className="col-6 text-end">
                            <p className="font-20 weight-700 color-orange mb-0 lh-1">{(e.ItemPrice - e.DiscountPrice).toLocaleString()} {t('thb')}</p>
                            <p className="oldPrice">{e.ItemPrice.toLocaleString()} {t('thb')}</p>
                          </div>
                        )}
                        {!e.IsDiscount && (
                          <div className="col-6 text-end">
                            <p className="font-18 weight-700 mb-0">{e.ItemPrice.toLocaleString()} {t('thb')}</p>
                          </div>
                        )}
                        <div className="col-12"><hr className="mt-2 mb-2" /></div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-6 desc">
                          <p className="font-10 mb-0">{t('quantity')}</p>
                        </div>
                        <div className="col-6 text-end">
                          <div className="input-group ms-auto">
                            <span className="input-group-btn">
                              <button type="button" className={`btn btn-number minus ${selectedT[index]}`} data-type="minus" data-field="quant[1]" onClick={() => {
                                let subT = [...subTicketQty]
                                let netT = e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice
                                if (subTicketQty[index].Qty > 0) {
                                  subT[index].Qty = subT[index].Qty - 1
                                  setSubTotalT(subTotalT => subTotalT = subTotalT - netT)
                                }
                                else subT[index].Qty = 0
                                setSubTicketQty(subT)
                              }}>
                                <span className="material-icons">remove</span>
                              </button>
                            </span>
                            <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={subTicketQty[index].Qty} defaultValue={1} min={1} max={e.Limit} disabled />
                            <span className="input-group-btn">
                              <button type="button" className={`btn btn-number plus ${selectedT[index]}`} data-type="plus" data-field="quant[1]" onClick={() => {
                                let subT = [...subTicketQty]
                                let netT = e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice
                                if (subT[index].Qty < e.Limit || e.Limit === null) {
                                  subT[index].Qty = subT[index].Qty + 1
                                  setSubTotalT(subTotalT => subTotalT = subTotalT + netT)
                                }
                                else subT[index].Qty = e.Limit
                                setSubTicketQty(subT)
                              }}>
                                <span className="material-icons">add</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

            <div className="box-body ">
              <div className="row align-items-center">
                <div className="col-6 pe-0">
                  <p className="font-14 weight-700 mb-0">{t('subtotal')}</p>
                </div>
                <div className="col-6 text-end ps-0">
                  <p className="font-24 weight-900 color-orange mb-0"> {subTotalT.toLocaleString()} <span className="font-18"> {t('thb')} </span></p>
                </div>
              </div>
            </div>
            <div className="d-block d-lg-none mb-4">
              {/* {routePath == '/16two' ? (
            <>
              <p className="d-flex font-14 mb-2 weight-600 text-decoration-underline" >
                <a href="https://columbiapicturesaquaverse.com/terms-and-conditions/" target="_blank">
                  <img src={TermIcon} className="TermIcon" />
                  {t('terms_conditions')}{' '}
                </a>
              </p>
            </>
          ) : (
            <> */}
              <p
                className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
                data-bs-toggle="collapse"
                href={'#term' + itemList.PackageCode}
                role="button"
                aria-expanded="false"
                aria-controls="term1"
              >
                <img src={TermIcon} className="TermIcon" />
                {t('terms_conditions')}{' '}
              </p>
              {/* </>
          )} */}

              <div className="collapse" id={'term' + itemList.PackageCode}>
                <div className="card card-body text-left">
                  <Markup content={itemList.PackageTermAndCondition} />
                </div>
              </div>
            </div>
            {showAddT && (
              <div className="col-12 d-">
                <button className="btn btn-main" type="button" disabled={!showAddT} onClick={() => {
                  let totalSelected = 0
                  let sT = [...selectedT]
                  let eT = [...editT]
                  let itemListLength = itemList.PackageItemLists.length
                  itemList.PackageItemLists.forEach((e, index) => {
                    if (editT[index] || (selectedT[index] === "" && !editT[index] && subTicketQty[index].Qty > 0)) {
                      addToCart({
                        PackageCode: e.PackageCode,
                        PackageName: itemList.PackageName,
                        ItemCode: e.ItemCode,
                        ItemName: e.ItemName,
                        Qty: subTicketQty[index].Qty,
                        Tax: 0,
                        ItemPrice: e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice,
                        ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                        PriceName: e.PriceName,
                        PriceUnit: '',
                        TotalPrice: subTicketQty[index].Qty * (e.IsDiscount ? e.ItemPrice - e.DiscountPrice : e.ItemPrice),
                        VisitDate: null,
                        VisitTime: '',
                        VisitLocation: '',
                        step: currentStep,
                        status: 'normal',
                        IsPromoDiscount: e.AllowDiscount,
                      }, editT[index]
                      )

                    }
                    if (subTicketQty[index].Qty > 0) {
                      sT[index] = "hidden"
                      eT[index] = false
                      totalSelected++
                    } else {
                      sT[index] = ""
                      eT[index] = false
                    }
                    setSelectedT(sT)
                    setEditT(eT)
                  })
                  if (totalSelected === itemListLength) {
                    setShowAddT(false)
                  } else setShowAddT(true)
                }
                }>{t('addtocart')}</button>
              </div>
            )}
            {!showAddT && (
              <span className="btn" ><Verified className="img- me-3" />{t('addtocart')} </span>
            )}
          </div>
          {isDesktopOrLaptop && (
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-1">
                  {/* {routePath == '/16two' ? (
                  <>
                    <a href="https://columbiapicturesaquaverse.com/terms-and-conditions/" target="_blank">
                      <p className="font-14 mb-2">
                        <img src={TermIcon} className="TermIcon" />
                        {t('terms_conditions')}{' '}
                      </p>
                    </a>
                  </>
                ) : (
                  <> */}

                  {/* term แบบเดิม -------------------------------- */}
                  {/* <p className="font-14 mb-2">
                      <img src={TermIcon} className="TermIcon" />
                      {t('terms_conditions')}{' '}
                    </p>
                    <Markup content={itemList.PackageTermAndCondition} /> */}

                  <p
                    className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
                    data-bs-toggle="collapse"
                    href={'#term' + itemList.PackageCode}
                    role="button"
                    aria-expanded="false"
                    aria-controls="term1"
                  >
                    <img src={TermIcon} className="TermIcon" />
                    {t('terms_conditions')}{' '}
                  </p>
                  <div className="collapse" id={'term' + itemList.PackageCode}>
                    <div className="card card-body text-left">
                      <Markup content={itemList.PackageTermAndCondition} />
                    </div>
                  </div>

                  {/* </>
                )} */}
                </div>
              </div>
              <hr />
            </div>
          )}
          
        </div>
      </div>
    </div>
  )
}

