import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from "../component/img/close.svg";
import { useNavigate } from 'react-router-dom';

export const PackageCard4Buy = (props) => {
    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    const { packageCard, packageList, netPrice, PackageItemlength, index, saveWord, routePath, isflip, packageTicket, apiLink, baseLink, subTicket, language } = props

    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [equalArray, setEqualArray] = useState([]);
    const [notEqualArray, setNotEqualArray] = useState([]);
    const [isNavigate, setIsNavigate] = useState(false);
    const navigate = useNavigate();

    const addToCart = (item, isEdit) => {
        myCart = sessionStorage.getItem('MyCart')
        myCart = JSON.parse(myCart)

        //เช็ค package เมื่อเปลี่ยน channel แล้วเลือกสินค้า จะ alert ให้ลบ package ที่ไม่มีใน channel นั้น --------------------------
        if (myCart || myCart != null) {
            let allTicket = []
            if (routePath == "") {
                if (myCart.length > 0 && packageTicket.length > 0) {
                    packageTicket.forEach((item) => { allTicket.push(item) })
                    console.log("moreTrillItem", subTicket.moreTrillItem)
                    if (!!subTicket.moreTrillItem && subTicket.moreTrillItem.length > 0) {
                        subTicket.moreTrillItem.forEach((x) => { allTicket.push(x) })
                    }
                    if (!!subTicket.fbItem && subTicket.fbItem.length > 0) {
                        subTicket.fbItem.forEach((x) => { allTicket.push(x) })
                    }
                    if (!!subTicket.extra && subTicket.extra.length > 0) {
                        subTicket.extra.forEach((x) => { allTicket.push(x) })
                    }
                }
            } else {
                if (myCart.length > 0 && packageTicket.length > 0) {
                    packageTicket.forEach((item) => { allTicket.push(item) })
                }
            }
            console.log("allTicket", allTicket)

            const notEqualArray = myCart.filter((cart) => !allTicket.some((api) => cart.PackageCode === api.PackageCode));
            const EqualArray = myCart.filter((cart) => allTicket.some((api) => cart.PackageCode === api.PackageCode));

            console.log("notEqualArray Before", notEqualArray)
            console.log("EqualArray Before", EqualArray)
            if (notEqualArray.length > 0) {
                setNotEqualArray(notEqualArray)
                setEqualArray(EqualArray)
                console.log("notEqualArray after", notEqualArray)
                console.log("EqualArray after", EqualArray)
                deletePackage(item, isEdit)
                // setShowAlert(true)
                setAlertText("delete_not_equal")
                return
            } else {
                setIsNavigate(true)
                props.addToCart(item, isEdit)
            }
        } else {
            setIsNavigate(true)
            props.addToCart(item, isEdit)
        }
        //--------------------------------------------------------------------------------------------------------------
        console.log("packageCard addToCart", packageCard)
    }


    const deletePackage = async (item, isEdit) => {
        if (!!user && !!user.confirmationNo) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'accept-language': language },
                body: JSON.stringify({
                    UserCode: user.userCode,
                    ConfirmationNo: user.confirmationNo
                }),
            };
            // /afcl/Package/Cart/RemovePackage
            const res = await fetch(apiLink + '/afcl/Package/Cart/RemovePackage', requestOptions);
            res
                .json()
                .then((res) => {
                    const result = res['Success']
                    if (result) {
                        user.confirmationNo = ""
                        sessionStorage.setItem('UserLogin', JSON.stringify(user));
                        sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
                        setShowAlert(false)
                        setIsNavigate(true)
                        props.addToCart(item, isEdit)
                        navigate('/ticket/' + packageCard.PackageCode + '/' + packageCard.StepId)
                        console.log("equalArray", equalArray)
                        // window.location.reload();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
            setShowAlert(false)
            setIsNavigate(true)
            props.addToCart(item, isEdit)
            // navigate('/ticket/' + packageCard.PackageCode + '/' + packageCard.StepId)
            console.log("equalArray", equalArray)
            window.location.reload();
        }

    }




    // ภาษา
    const { t } = useTranslation();
    return (
        <>
            <div className="card-hottest-right" style={{ width: index < 2 ? '50%' : '30%', position: 'relative' }}>
                <div className="card-body d-flex flex-column" style={{ padding: index < 2 ? '0px 10px 0px 10px' : '' }}>
                    <h5 className="mb-1 lh-1 border-bottom-dot" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: index < 2 ? '20px' : '24px', color: '#000000', paddingBottom: index < 2 ? '0px' : '', height: index < 2 ? '45px' : '60px' }}>{packageCard.PackageName}</h5>
                </div>

                <div className="card-footer text-muted">
                    <div style={{ height: index < 2 ? 40 : 50, display: 'flex', justifyContent: 'center', alignItems: 'baseline', margin: packageCard.IsDiscount ? '' : '10px' }}>
                        <h1 className="weight-900 lh-1" style={{ fontSize: '40px', color: '#001D41', marginRight: '10px', fontWeight: '900' }}>
                            {(packageCard.isExCludeVat ? (packageCard.ItemPriceExVat - packageCard.ItemDiscountExVat) : (packageCard.PackagePrice - packageCard.DiscountPrice)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </h1>
                        <h1 style={{ fontSize: '20px', color: '#001D41' }}>{t('thb')} </h1>
                    </div>
                    {packageCard.IsDiscount && (
                        <>
                            <div style={{ height: index < 2 ? 20 : 30, display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                                <h1 className="weight-900 lh-1" style={{ fontSize: index < 2 ? '20px' : '25px', color: '#F87B17', marginRight: '5px', fontWeight: '900', textDecorationLine: 'line-through' }}>
                                    {(packageCard.isExCludeVat ? packageCard.ItemPriceExVat : packageCard.PackagePrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                </h1>
                            </div>

                            <div className="mb-1" style={{ minHeight: 40, justifyContent: 'center', display: 'flex' }}>
                                <h1 className="weight-900 lh-1 d-flex align-items-center" style={{ fontSize: index < 2 ? '20px' : '22px', color: '#34995C', marginRight: '5px', fontWeight: '900', textAlign: 'center' }}>
                                    {t('save_price').toUpperCase() + ' ' + (packageCard.isExCludeVat ? packageCard.ItemDiscountExVat : packageCard.DiscountPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + t('thb')}
                                </h1>
                                {/* <div className="save" style={{ height: index < 2 ? 50 : '', fontSize: index < 2 ? 18 : 20, textAlign: 'center' }}>{t(saveWord) + packageCard.DiscountPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + t('thb')}</div> */}

                            </div>
                        </>
                    )}
                    <div className="area-detail" style={{ height: index < 2 ? '' : '14em', marginTop: index < 2 ? '0px' : '10px' }}>
                        {packageList.map((e, i) => {
                            return (
                                <div key={i} className="text-detail" style={{
                                    fontSize: index < 2 ? '13px' : '15px',
                                    width: index < 2 ? PackageItemlength > 4 ? '50%' : '100%' : PackageItemlength > 7 ? '50%' : '100%',
                                    paddingLeft: index < 2 ? PackageItemlength > 4 ? 20 : 50 : PackageItemlength > 7 ? 50 : 60,
                                }}>
                                    {e.ItemType == 'Ticket' ? '●' : '+'} {e.Qty} {e.ItemName}
                                </div>
                            )
                        })}

                        <div style={{ position: 'absolute', bottom: '60px', left: '20px', width: '90%' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '14px', color: 'black', margin: 0 }}>
                                {packageCard.isExCludeVat && '*' + t('price_ex_vat')}
                            </p>
                        </div>

                        <div className="text-center" style={{ position: 'absolute', bottom: '15px', left: '20px', width: '90%' }}>
                            <a href={isNavigate ? baseLink + routePath + "/ticket/" + packageCard.PackageCode + "/" + packageCard.StepId : '##'}>
                                <button type="button" className="btn btn-main mt-auto mx-auto align-self-start "
                                    onClick={() => {
                                        if (!packageCard.IsSaleByItem) {
                                            addToCart(
                                                {
                                                    PackageCode: packageCard.PackageCode,
                                                    PackageName: packageCard.PackageName,
                                                    ItemCode: "",
                                                    ItemName: "",
                                                    ItemType: packageCard.ItemType,
                                                    Qty: 1,
                                                    Tax: 0,
                                                    ItemPrice: netPrice,
                                                    ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                                                    PriceName: packageCard.PriceName,
                                                    PriceUnit: '',
                                                    TotalPrice: netPrice,
                                                    VisitDate: null,
                                                    VisitTime: '',
                                                    VisitLocation: '',
                                                    step: packageCard.StepId,
                                                    status: 'normal',
                                                    ItemPriceExVat: (packageCard.ItemPriceExVat - packageCard.ItemDiscountExVat),
                                                    ItemDiscountExVat: packageCard.IsDiscount ? packageCard.ItemDiscountExVat : 0,
                                                    IsPromoDiscount: packageCard.AllowDiscount,
                                                }, false
                                            )
                                        }
                                    }}
                                >
                                    {t('buy_now')}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>


                {/* Delete Confirm Popup ------------------------------------------------------- */}
                {/* <Modal show={showAlert} className="col-12 cart-placeorder">
                    <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
                    <h5 className="text-center text-danger mb-5">{t(alertText)}</h5>
                    {notEqualArray && notEqualArray.map((item, index) => ((
                        <h5 className="text-center" key={index}>{item.PackageName} : {t('price')} {item.TotalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</h5>
                    )))}
                    <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <a className="w-100" href={isNavigate ? baseLink + routePath + "/ticket/" + packageCard.PackageCode + "/" + packageCard.StepId : '##'}>
                            <button className="btn" style={{ height: '40px', background: '#d63737', borderRadius: '5px', fontWeight: '700', border: '0px', color: '#fff' }}
                                onClick={() => deletePackage(
                                    {
                                        PackageCode: packageCard.PackageCode,
                                        PackageName: packageCard.PackageName,
                                        ItemCode: "",
                                        ItemName: "",
                                        ItemType: packageCard.ItemType,
                                        Qty: 1,
                                        Tax: 0,
                                        ItemPrice: netPrice,
                                        ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                                        PriceName: packageCard.PriceName,
                                        PriceUnit: '',
                                        TotalPrice: netPrice,
                                        VisitDate: null,
                                        VisitTime: '',
                                        VisitLocation: '',
                                        step: packageCard.StepId,
                                        status: 'normal'
                                    }, false
                                )}>{t('delete')}</button>
                        </a>
                    </div>
                </Modal> */}
            </div>
        </>
    )
}
