import React, {useEffect, useState} from 'react'
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";

//Image//
import { ReactComponent as BS } from "../../component/img/best-seller.svg";
import { ReactComponent as BV } from "../../component/img/best-value2.svg";
import { baseLink} from '../../constants/DataPublic'
import { useGlobalState } from '../../global'


export const PackageCardH = (props) => {

    const {packageCard} = props 

    const [routePath, setRoutePath] = useGlobalState('route');
    const [picturePath, setPicturePath] = useState()
    const [netPrice, setNetPrice] = useState(0)

    useEffect(()=>{
        if (packageCard.PackagePictureLists.length > 0){

            setPicturePath(packageCard.PackagePictureLists[1].PictureUrl)
            if (packageCard.IsDiscount){
                let netP = packageCard.PackagePrice - packageCard.DiscountPrice
                setNetPrice(netP)
            } else setNetPrice(packageCard.PackagePrice)
        }
    },[])  

    const addToCart = (item, isEdit) =>{
      props.onAddToCart(item,isEdit)
    }
    
    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="col-12 mb-4 d-flex align-items-stretch">
          <div className="card card-horizontal">
            <div className="row g-0 h-100">

              {/* card image */}
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <img src={picturePath} className="img-fluid card-img rounded-start" alt="..." />
              </div>
              {/* card image */}

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 rightside box-addons">
                {/* card detail */}
                <div className="card-body">
                  <h5 className="card-title font-24 mb-1 lh-1">{packageCard.PackageName}</h5>
                  <p className="card-text mb-1 font-10 color-grey weight-500"><Markup content={packageCard.PackageDescription}/></p>
                </div>
                {/* card detail */}

                {/* card footer */}
                <div className="home-card-footer text-muted">
                  <div className="d-flex mb-3 align-items-center justify-content-between">
                    <div className>
                      <p className="font-24 weight-900 color-orange mb-0 lh-1">{netPrice.toLocaleString()} {t('thb')}<span className="font-14 weight-600 color-black">{packageCard.PriceUnit}</span></p>
                      <p className="oldPrice mb-0">{packageCard.PackagePrice.toLocaleString()} {t('thb')}</p>
                    </div>
                    {packageCard.IsAddonDiscount && (
                      <span className="bubble bubble-small mb-0 font-10 weight-700">3rd Ticket at 50% Discount</span>
                    )}
                  </div>
                  <div className="text-center">
                    <a href={baseLink+ routePath +"/ticket/"+packageCard.PackageCode+"/"+packageCard.StepId}>
                      <button type="button" className="btn btn-main mt-auto mx-auto align-self-start "
                      onClick={() => {
                        if (!packageCard.IsSaleByItem){
                        addToCart(
                        {
                            PackageCode: packageCard.PackageCode,
                            PackageName: packageCard.PackageName,
                            ItemCode: "",
                            ItemName: "",
                            Qty: 1,
                            Tax: 0,
                            ItemPrice: netPrice,
                            ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                            PriceName: packageCard.PriceName,
                            PriceUnit: '',
                            TotalPrice: netPrice,
                            VisitDate: null,
                            VisitTime: '',
                            VisitLocation: '',
                            step: packageCard.StepId,
                            status: 'normal',
                            IsPromoDiscount: packageCard.AllowDiscount,
                          }, false
                        ) 
                        }
                      }}
                      >{t('buy_now')}</button></a>	
                  </div>
                </div>
                {/* card footer */}

              </div>
            </div>
          </div>
        </div>
    )
}
