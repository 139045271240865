import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { ReactComponent as Close } from "../img/close.svg";

const CartList = (props) => {

    const { cartItem } = props
    const { currentStep } = props
    const { checkLastItem } = props
    const { IsIncludeVat } = props

    const [itemQty, setItemQty] = useState(cartItem.Qty)

    const [removePackageCode, setRemovePackageCode] = useState("")
    const [removeItemCode, setRemoveItemCode] = useState("")
    const [removeStep, setRemoveStep] = useState("5")

    const [showConfirm, setShowConfirm] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setItemQty(cartItem.Qty)
        console.log('cartItem', cartItem)
    }, [cartItem])


    const addToCart = (item, isEdit) => {
        console.log("addToCart", item);
        props.onAddToCart(item, isEdit)
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="row" id="index">
            <div className="col-6">
                <p className="font-14 weight-700 mb-0">{cartItem.PackageName !== '' ? cartItem.PackageName : cartItem.ItemName}</p>
                <p className="font-14 weight-400 color-grey mb-0">{(cartItem.ItemCode === "" ? (cartItem.PriceUnit == null ? "" : cartItem.PriceUnit) : cartItem.ItemName) + (cartItem.VisitDate != "" && cartItem.VisitDate != null ? t('visitdate') + ": " + cartItem.VisitDate.substr(8, 2) + "/" + cartItem.VisitDate.substr(5, 2) + "/" + cartItem.VisitDate.substr(0, 4) : "")}</p>
                <div className="font-12 weight-400 color-grey mb-0">
                    <div className="input-group ms-auto-cart mt-2">
                        <span className="input-group-btn">
                            <button type="button" className="btn  btn-number minus" data-type="minus" data-field="quant[1]"
                                onClick={() => {
                                    if (itemQty > 1) {
                                        let newQty = itemQty - 1
                                        setItemQty(itemQty => newQty)
                                        addToCart({
                                            PackageCode: cartItem.PackageCode,
                                            PackageName: cartItem.PackageName,
                                            ItemCode: cartItem.ItemCode,
                                            ItemName: cartItem.ItemName,
                                            Qty: newQty,
                                            Tax: 0,
                                            ItemPrice: cartItem.ItemPrice,
                                            ItemDiscount: cartItem.ItemDiscount,
                                            PriceName: cartItem.PriceName,
                                            PriceUnit: cartItem.PriceUnit,
                                            TotalPrice: (newQty) * cartItem.ItemPrice,
                                            VisitDate: cartItem.VisitDate,
                                            VisitTime: cartItem.VisitTime,
                                            VisitLocation: cartItem.VisitLocation,
                                            step: cartItem.step,
                                            status: 'normal',
                                            ItemPriceExVat: cartItem.ItemPriceExVat,
                                            TotalPriceExVat: (newQty) * cartItem.ItemPriceExVat,
                                            ItemDiscountExVat: cartItem.IsDiscount ? cartItem.ItemDiscountExVat : 0,
                                            IsPromoDiscount: cartItem.AllowDiscount,
                                        }, true
                                        )
                                        props.setCartUpdate({
                                            PackageCode: cartItem.PackageCode,
                                            ItemCode: cartItem.ItemCode,
                                            Qty: newQty,
                                            TotalPrice: (newQty) * cartItem.ItemPrice,
                                            step: cartItem.step
                                        })
                                        //   }

                                    }
                                    else {
                                        setRemovePackageCode(cartItem.PackageCode)
                                        setRemoveItemCode(cartItem.ItemCode)
                                        setRemoveStep(cartItem.step)
                                        setShowConfirm(true)
                                    }
                                }}>
                                <span className="material-icons">remove</span>
                            </button>
                        </span>
                        <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={itemQty} min={0} disabled />
                        <span className="input-group-btn">
                            <button type="button" className="btn btn-number plus" data-type="plus" data-field="quant[1]"
                                onClick={() => {
                                    let newQty = itemQty + 1
                                    setItemQty(itemQty => newQty)
                                    addToCart({
                                        PackageCode: cartItem.PackageCode,
                                        PackageName: cartItem.PackageName,
                                        ItemCode: cartItem.ItemCode,
                                        ItemName: cartItem.ItemName,
                                        Qty: newQty,
                                        Tax: 0,
                                        ItemPrice: cartItem.ItemPrice,
                                        ItemDiscount: cartItem.ItemDiscount,
                                        PriceName: cartItem.PriceName,
                                        PriceUnit: cartItem.PriceUnit,
                                        TotalPrice: (newQty) * cartItem.ItemPrice,
                                        VisitDate: cartItem.VisitDate,
                                        VisitTime: cartItem.VisitTime,
                                        VisitLocation: cartItem.VisitLocation,
                                        step: cartItem.step,
                                        status: 'normal',
                                        ItemPriceExVat: cartItem.ItemPriceExVat,
                                        TotalPriceExVat: (newQty) * cartItem.ItemPriceExVat,
                                        ItemDiscountExVat: cartItem.IsDiscount ? cartItem.ItemDiscountExVat : 0,
                                        IsPromoDiscount: cartItem.AllowDiscount,
                                    }, true
                                    )
                                    props.setCartUpdate({
                                        PackageCode: cartItem.PackageCode,
                                        ItemCode: cartItem.ItemCode,
                                        Qty: newQty,
                                        TotalPrice: (newQty) * cartItem.ItemPrice,
                                        step: cartItem.step
                                    })
                                }}>
                                <span className="material-icons">add</span>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-6 text-end">
                <p className="font-18 weight-700 mb-0">{(!IsIncludeVat ? (cartItem.ItemPriceExVat * itemQty) : (cartItem.ItemPrice * itemQty)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
            </div>
            <div className="col-12"><hr /></div>
            <Modal show={showConfirm} className="col-12 delete-Item">
                <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowConfirm(false)} /></a>
                {checkLastItem ?
                    <h5 className="text-center py-4">{t('confirm_delete_package')}</h5>
                    :
                    <h5 className="text-center py-4">{t('confirm_delete_cart')}</h5>
                }


                <div className="row">
                    <div className="col-6">
                        <button className="btn btn-blue mb-3" type="button"
                            onClick={() => {
                                props.onRemoveCart(removePackageCode, removeItemCode)
                                // if (currentStep != 5){
                                navigate("/ticket/" + removePackageCode + "/" + currentStep)
                                window.location.reload()
                                // }
                                setShowConfirm(false)
                            }}>{t('confirm')}</button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-outline-secondary mb-3" type="button" onClick={() => setShowConfirm(false)}>{t('cancel')}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CartList
