import './landingnopackage.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import { apiLink, baseLink } from '../constants/DataPublic';
import { useMediaQuery } from 'react-responsive'
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from "../component/img/close.svg";
import { useNavigate } from 'react-router-dom';

// import songkranMbTH from '../component/img/songkran/mb-th.jpg';
// import songkranMbEN from '../component/img/songkran/mb-en.jpg';
import vipBackgroundEN from '../component/img/vip-background-pc-en.jpg';
import vipBackgroundTH from '../component/img/vip-background-pc-th.jpg';
import { useGlobalState } from '../global'

//Component
const LandingPageNoPackage = (props) => {
    const { language, is16Two } = props;
    const [routePath, setRoutePath] = useGlobalState('route');
    const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
    const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
    const [hardLink1, setHardlink1] = useState({});
    const [packageTicket, setPackageTicket] = useState([]);
    const [cart, setCart] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [equalArray, setEqualArray] = useState([]);
    const [notEqualArray, setNotEqualArray] = useState([]);
    const [isNavigate, setIsNavigate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 767px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(() => {
        if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
    }, [cart]);


    useEffect(async () => {
        getPackageTicket();
        getBanner();
        sessionStorage.removeItem('itCrossCampaign');
    }, [language]);


    async function getBanner() {
        setIsLoading(false)
        const requestOptions = {
            method: 'GET',
            headers: { 'accept-language': language },
        };
        let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
        const res = await fetch(apiLink + '/afcl/Package/Banner' + path, requestOptions);
        res
            .json()
            .then((res) => {
                let sortBanner = res['Result']['PackageBanner'].sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
                setHardlink1(sortBanner[0].PackageBannerButtons[0]);
                setIsLoading(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getPackageTicket = async () => {
        setIsLoading(false)
        const requestOptions = {
            method: 'GET',
            headers: { 'accept-language': language },
        };
        let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
        const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
        res
            .json()
            .then((res) => {
                setPackageTicket(res['Result']['Packages']);
                setIsLoading(true)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onCheckPackageToAddCart = (packageCode) => {
        let pk = packageTicket.find((x) => x.PackageCode.toLocaleLowerCase() == packageCode.toLocaleLowerCase());
        if (pk != null) {
            onAddToCart(
                {
                    PackageCode: pk.PackageCode,
                    PackageName: pk.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: 1,
                    Tax: 0,
                    ItemPrice: pk.PackagePrice - pk.DiscountPrice,
                    ItemDiscount: pk.DiscountPrice,
                    PriceName: pk.PriceName,
                    PriceUnit: '',
                    TotalPrice: pk.PackagePrice - pk.DiscountPrice,
                    VisitDate: null,
                    VisitTime: '',
                    VisitLocation: '',
                    step: 1,
                    status: 'normal',
                    IsPromoDiscount: pk.AllowDiscount,
                },
                false,
            );
        } else {
        }
    }

    const onClickBanner = (packageCode) => {
        myCart = sessionStorage.getItem('MyCart')
        myCart = JSON.parse(myCart)

        //เช็ค package เมื่อเปลี่ยน channel แล้วเลือกสินค้า จะ alert ให้ลบ package ที่ไม่มีใน channel นั้น --------------------------
        if (myCart || myCart != null) {
            const notEqualArray = myCart.filter((cart) => !packageTicket.some((api) => cart.PackageCode === api.PackageCode));
            const EqualArray = myCart.filter((cart) => packageTicket.some((api) => cart.PackageCode === api.PackageCode));

            if (notEqualArray.length > 0) {
                setNotEqualArray(notEqualArray)
                setEqualArray(EqualArray)
                deletePackage(packageCode)
                // setShowAlert(true)
                setAlertText("delete_not_equal")
                return
            } else {
                setIsNavigate(true)
                onCheckPackageToAddCart(packageCode)
            }
        } else {
            setIsNavigate(true)
            onCheckPackageToAddCart(packageCode)
        }
    };

    // const deletePackage = (packageCode) => {
    //     sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
    //     setShowAlert(false)
    //     setIsNavigate(true)
    //     onCheckPackageToAddCart(packageCode)
    //     window.location.reload();
    // }

    const deletePackage = async (packageCode) => {
        if (!!user && user.confirmationNo != "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'accept-language': language },
                body: JSON.stringify({
                    UserCode: user.userCode,
                    ConfirmationNo: user.confirmationNo
                }),
            };
            // /afcl/Package/Cart/RemovePackage
            const res = await fetch(apiLink + '/afcl/Package/Cart/RemovePackage', requestOptions);
            res
                .json()
                .then((res) => {
                    const result = res['Success']
                    if (result) {
                        setShowAlert(false)
                        setIsNavigate(true)
                        user.confirmationNo = ""
                        sessionStorage.setItem('UserLogin', JSON.stringify(user));
                        sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
                        onCheckPackageToAddCart(packageCode)
                        console.log('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
                        navigate('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
            setShowAlert(false)
            setIsNavigate(true)
            onCheckPackageToAddCart(packageCode)
            window.location.reload();
        }

    }

    const onAddToCart = (item, isEdit) => {
        myCart = sessionStorage.getItem('MyCart');
        myCart = JSON.parse(myCart);

        let aIndex = -1;

        if (myCart != null) {
            aIndex = myCart.findIndex((e) => {
                return e.PackageCode === item.PackageCode;
            });
        } else {
            myCart = [];
        }

        if (aIndex === -1) {
            // เพิ่ม Cart
            if (item.Qty > 0) {
                myCart = [...myCart, item];
                setCart(myCart);
            }
        } else {
            // แก้ไข Cart
            let preQty = myCart[aIndex].Qty;
            let newQty = preQty + 1;

            const editCart = myCart.map((e) => {
                if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
                    return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' };
                }
                return e;
            });
            setCart(editCart);
        }
    };

    const { t } = useTranslation();

    return (
        <div className="page homepage" style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>

            <ImageLoader src={vipBackgroundEN} transitionTime='0s'>
                {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                    <>
                        {shouldShowLoader && !hasFailed && (
                            <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                                <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                            </div>
                        )}
                        {/* {hasFailed && (
                            <div className='AwesomeoLoader__failed'>
                                Loading Fail :(
                            </div>
                        )} */}
                        {hasLoaded && (
                            <>
                                {isDesktopOrLaptop && (
                                    <>
                                        <div style={{ padding: '10px', fontSize: '14px', background: '#0B3259', width: '100%', height: 'auto', minHeight: '50px', color: '#ffffff', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                            {t('banner_header')}
                                        </div>
                                        <div style={{ width: '100%', position: 'relative' }}>
                                            <a href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1' : '##'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                                                {is16Two ? (
                                                    <img src={language == 'en' ? vipBackgroundEN : vipBackgroundTH} style={{ width: '100%' }}></img>
                                                ) : (
                                                    <img src={language == 'en' ? vipBackgroundEN : vipBackgroundTH} style={{ width: '100%' }}></img>
                                                )}
                                            </a>
                                        </div>
                                    </>
                                )}

                                {isTabletOrMobile &&
                                    <>
                                        <div style={{ padding: '10px', fontSize: '8px', background: '#0B3259', width: '100%', height: 'auto', minHeight: '50px', color: '#ffffff', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                            {t('banner_header')}
                                        </div>
                                        <div style={{ width: '100%', position: 'relative' }}>
                                            <a href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1' : '##'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                                                {is16Two ? (
                                                    <img src={language == 'en' ? vipBackgroundEN : vipBackgroundTH} style={{ width: '100%' }}></img>
                                                ) : (
                                                    <img src={language == 'en' ? vipBackgroundEN : vipBackgroundTH} style={{ width: '100%' }}></img>
                                                )}
                                            </a>
                                        </div>
                                    </>
                                }
                            </>
                        )}
                    </>
                )}
            </ImageLoader>

            {!isLoading && (
                <div className="spinner">
                    <CircularProgress />
                </div>
            )}



            <Modal show={showAlert} className="col-12 cart-placeorder">
                <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
                <h5 className="text-center text-danger mb-5">{t(alertText)}</h5>
                {notEqualArray && notEqualArray.map((item, index) => ((
                    <h5 className="text-center" key={index}>{item.PackageName} : {t('price')} {item.TotalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</h5>
                )))}
                <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <a className="w-100" href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1' : '##'}>
                        <button className="btn" style={{ height: '40px', background: '#d63737', borderRadius: '5px', fontWeight: '700', border: '0px', color: '#fff' }} onClick={() => deletePackage(hardLink1.ButtonLinkUrl)}>{t('delete')}</button>
                    </a>
                </div>
            </Modal>
        </div>
    );
};

export default LandingPageNoPackage
